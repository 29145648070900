import React from "react";
import { Link } from "gatsby";
import BlockContent from "./block-content";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import Container from "./container";
import RoleList from "./role-list";
import SlideShow from "./slideshow_new";
import { useItemContext } from "../context/item/context";
import * as styles from "./project.module.css";
import Notification from "./notification";

function Project(props) {
  const {
    available,
    _rawBody,
    title,
    categories,
    mainImage,
    imagesGallery,
    price,
    members,
    relatedProjects,
  } = props;
  const { state, dispatch } = useItemContext();
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [notify, setNotify] = React.useState({ show: false });

  React.useEffect(() => {
    const faIdArray = state.item.map((item) => item.id);
    setIsFavorite(faIdArray.includes(props.id));
  }, []);
  function add(data) {
    dispatch({ type: "add", payload: data });
    setIsFavorite(true);
    setNotify({
      show: true,
      text: "Hozzáadva a kedvencekhez!",
      class: "success",
    });
    setTimeout(() => {
      setNotify({ show: false });
    }, 5000);
  }
  function remove(data) {
    dispatch({ type: "remove", payload: data });
    setIsFavorite(false);
    setNotify({
      show: true,
      text: "Eltávolítva a kedvencek közül!",
      class: "success",
    });
    setTimeout(() => {
      setNotify({ show: false });
    }, 5000);
  }
  return (
    <>
    {notify.show && <Notification text={notify.text} class={notify.class} />}
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
          <SlideShow imagesGallery={imagesGallery} mainImage={mainImage}/>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
            <hr />
          </div>
          <aside className={styles.metaContent}>
            <h1 className={styles.title}>{title}</h1>
            {available ? (
              !isFavorite ? (
                <button className={styles.addFavorites} onClick={() => add(props)}>Kedvencekhez ad!</button>
              ) : (
                <button className={styles.removeFavorites}  onClick={() => remove(props)}>Eltávolít!</button>
              )
            ) : null}
            <p style={{ color: "red" }}>{available ? "" : "A termék jelenleg nem elérhető"}</p>
            <p>{price && price} Ft</p>
            {members && members.length > 0 && <RoleList items={members} title="Project members" />}
            {categories && categories.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Kategória</h3>
                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>
                      {" "}
                      <Link to={`/kategoriak/${category.slug.current}`}>{category.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {relatedProjects && relatedProjects.length > 0 && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>Kapcsolodó termékek</h3>
                <ul>
                  {relatedProjects.map((project) => (
                    <li key={`related_${project._id}`}>
                      {project.slug ? (
                        <Link to={`/taskak/${project.slug.current}`}>{project.title}</Link>
                      ) : (
                        <span>{project.title}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
    </>
  );
}

export default Project;
