// extracted by mini-css-extract-plugin
export var addFavorites = "project-module--addFavorites--ylfDi";
export var categories = "project-module--categories--nV++O";
export var categoriesHeadline = "project-module--categoriesHeadline--jC5p1 typography-module--base--FphVn";
export var grid = "project-module--grid--RTc5P";
export var mainContent = "project-module--mainContent--W01Uw";
export var mainImage = "project-module--mainImage--vGvrQ";
export var metaContent = "project-module--metaContent--O+2BQ";
export var relatedProjects = "project-module--relatedProjects--CX5M0";
export var relatedProjectsHeadline = "project-module--relatedProjectsHeadline--mpdAZ typography-module--base--FphVn";
export var removeFavorites = "project-module--removeFavorites--YO+zM";
export var root = "project-module--root--+-3Dc";
export var title = "project-module--title--CAXQB typography-module--responsiveTitle1--0t0jA";