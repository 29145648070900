import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

const SlideShowNew = (props) => {
  let showThumbnails = true;
  const images = [];
  if (props.imagesGallery && props.imagesGallery.length > 0) {
    props.imagesGallery.map((image) => {
      images.push({
        original: imageUrlFor(buildImageObj(image)).width(1200).format("webp").fit("crop").url(),
        thumbnail: imageUrlFor(buildImageObj(image)).width(120).format("webp").fit("crop").url(),
        fullscreen: imageUrlFor(buildImageObj(image)).width(2400).format("webp").fit("crop").url(),
        alt: image.alt,
      });
    });
    showThumbnails = true;
  } else {
    images.push({
      original: imageUrlFor(buildImageObj(props.mainImage))
        .width(1200)
        .format("webp")
        .fit("crop")
        .url(),
      thumbnail: imageUrlFor(buildImageObj(props.mainImage))
        .width(120)
        .format("webp")
        .fit("crop")
        .url(),
      fullscreen: imageUrlFor(buildImageObj(props.mainImage))
        .width(2400)
        .format("webp")
        .fit("crop")
        .url(),
      alt: props.mainImage.alt,
    });
    showThumbnails = false;
  }

  return (
    <ImageGallery
      items={images}
      showPlayButton={false}
      lazyLoad={true}
      showThumbnails={showThumbnails}
    />
  );
};

export default SlideShowNew;
