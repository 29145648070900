import React from "react";
import * as styles from "./notification.module.css";
const Notification = (props) => {
  return (
    <div className={`${styles[props.class]} ${styles.notification}`}>
      <p>{props.text}</p>
    </div>
  );
};

export default Notification;
